import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// import { ToastrModule } from 'ng6-toastr-notifications';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { UiSwitchModule } from 'ngx-ui-switch';
import { AuthGuard } from './commonservice/auth.guard'
import { AuthguardGuardGuard } from './commonservice/authguard-guard.guard'
import { GetInterceptorService } from './b2c/services/get-interceptor/get-inerceptor.service';
import { SetInterceptorService } from './b2c/services/set-interceptor/set-interceptor.service';

import { ToastrModule } from 'ngx-toastr';
import { EmailVerificationComponent } from './email-verification/email-verification.component';
import { MatDialogModule } from '@angular/material/dialog';
import { ReactiveFormsModule} from '@angular/forms';
import { ModalComponent } from './b2c/pages/ticket/modal/modal.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { RouteGuard } from './commonservice/route/route.guard';
import { MatDialogComponent } from './b2c/pages/mat-dialog/mat-dialog.component';
// import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { StaticComponent } from './static/static.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { AboutComponent } from './about/about.component';
import { CommonModule } from '@angular/common';
import { ModallComponent } from './b2c/pages/order/new-order-list/modall/modall.component';
import { ModallllComponent } from './b2c/pages/driver/modallll/modallll.component';
import { ModaalComponent } from './b2c/pages/vendors/modaal/modaal.component';
import { NgxEchartsModule } from 'ngx-echarts';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';


import * as echarts from 'echarts';


@NgModule({
  declarations: [
    AppComponent,
    ModalComponent,
    ModaalComponent,
    ModallComponent,
    EmailVerificationComponent,
    MatDialogComponent,
    ResetPasswordComponent,
    StaticComponent,
    PrivacyComponent,
    AboutComponent,
    ModallllComponent
  ],
  imports: [

    NgxEchartsModule,
    BrowserModule,
    BrowserAnimationsModule,
    NgxIntlTelInputModule,
    ToastrModule.forRoot(),
    FormsModule,
    MatDialogModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    MatCheckboxModule,
    AppRoutingModule,
    CommonModule,
    // CKEditorModule,
    MatInputModule,
    ReactiveFormsModule,
    FormsModule,
    MatFormFieldModule,
    NgMultiSelectDropDownModule.forRoot(),
    UiSwitchModule
  ],
  providers: [AuthGuard, AuthguardGuardGuard,RouteGuard,
    { provide: HTTP_INTERCEPTORS, useClass: GetInterceptorService, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: SetInterceptorService, multi: true }
  ],
    entryComponents:[
    ModalComponent,MatDialogComponent,ModallllComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
