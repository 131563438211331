import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RouteGuard implements CanActivate {
  role: any;
  access: any;
  keyy: any;
  canActivate(key): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      this.role=JSON.parse(localStorage.getItem('Dayfresh_Admin'));
      this.access=JSON.parse(localStorage.getItem('permission'));
      // console.log(key._routerState.url);
      if(key._routerState.url=='/b2c/categories'){this.keyy= 'manageCategoryView'}
      if(key._routerState.url=='/b2c/sub-category'){this.keyy= 'manageSubCategoryView'}
      if(key._routerState.url=='/b2c/banner'){this.keyy= 'manageBannerView'}
      if(key._routerState.url=='/b2c/offer'){this.keyy= 'manageDealView'}
      if(key._routerState.url=='/b2c/users'){this.keyy= 'manageUserView'}
      if(key._routerState.url=='/b2c/drivers'){this.keyy= 'manageDriverView'}
      if(key._routerState.url=='/b2c/geofence'){this.keyy= 'manageGeofenceView'}
      if(key._routerState.url=='/b2c/order'){this.keyy= 'manageOrderView'}
      if(key._routerState.url=='/b2c/packs'){this.keyy= 'manageInventryView'}
      if(key._routerState.url=='/b2c/time-slot'){this.keyy= 'manageTimesloatView'}
      if(key._routerState.url=='/b2c/coupon'){this.keyy= 'manageCouponView'}
      if(key._routerState.url=='/b2c/broadcast'){this.keyy= 'manageBroadcastWrite'}
      if(key._routerState.url=='/b2c/setting'){this.keyy= 'manageSettingWrite'}
      if(key._routerState.url=='/b2c/sub-admin'){this.keyy= 'false'}
      if(key._routerState.url=='/b2c/vendors'){this.keyy= 'manageVendorView'}
      if(key._routerState.url=='/b2c/ticket'){this.keyy= 'manageTicketView'}
      // if(key._routerState.url=='/b2c/profile'){this.keyy= 'false'}
      if(key._routerState.url=='/b2c/reports'){this.keyy= 'manageCustomerReportedIssue'}
      if(key._routerState.url=='/b2c/return'){this.keyy= 'manageReturnOrderView'}

      
      // console.log(this.access[this.keyy])
      if((this.role.type=='SubAdmin' && this.access[this.keyy])||this.role.type=='Admin'){
        return true;
       } else if(this.role.type=='Vendor' && this.keyy== 'manageReturnOrderView' || this.keyy == 'manageInventryView' || this.keyy == 'manageOrderView' || this.keyy == 'manageDealView'){
        return true;
      }else{
        return false;
      }
  }
  
}