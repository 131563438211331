import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LoginBody } from 'src/app/b2b/requests/login-body';
import { ProductBody } from '../requests/product-body';
import { PackBody } from '../requests/pack-body';
import io from 'socket.io-client';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

let base = '';
// export default base = 'http://192.168.1.119:4003';
// export default base = "https://appgrowthcompany.com:5018";
export default base ="https://demo.appdukaan.com:5059"
// export default base = "https://groxery.com:5018";

@Injectable({
    providedIn: 'root'
})

export class ApiService {
    socket;
    adminId;
    // geofenceId;
     imageUrl = "https://demo.appdukaan.com:5059";
    // imageUrl = "https://appgrowthcompany.com:5018";
    // imageUrl = "https://groxery.com:5018";

       baseUrl = "https://demo.appdukaan.com:5059"
    // baseUrl = "https://appgrowthcompany.com:5018";
    // baseUrl = "https://groxery.com:5018";

       socketUrl = "https://demo.appdukaan.com:5059"
    // socketUrl = "https://appgrowthcompany.com:5018/";
        // socketUrl = "https://groxery.com:5018/";

    constructor(private http: HttpClient, private toaster: ToastrService) {
        let adminDetail = JSON.parse(localStorage.getItem('Dayfresh_Admin'));
        this.adminId = adminDetail._id;
        this.socket = io.connect( this.socketUrl, {
            reconnection: true,
            reconnectionDelay: 30000,
            reconnectionDelayMax : 60000,
            reconnectionAttempts: Infinity
        });
    }


    newOrder() {
        return Observable.create(observer => {
            var data = 'newOrder' + this.adminId;
            this.socket.removeListener(data);
            this.socket.on(data, data => {
                observer.next(data);
            });
        });
    }

    oredrResponse() {
        return Observable.create(observer => {
            this.socket.on('onlineAdmin', message => {
                observer.next(message);
            });
            this.socket.on('connect', res => {
            });
    
            const data = {
                adminId: this.adminId
            };
            this.socket.emit('onlineAdmin', data);
            this.socket.on('disconnect', message => {
                console.log('debug disconnect', message);
            });
        });
    }
    oredrNotification() {
        return Observable.create(observer => {
            this.socket.on('orderNotification', message => {
                observer.next(message);
            });
            this.socket.on('connect', res => {
            });
    
            const data = {
                onlineAdmin : this.adminId
            };
            this.socket.emit('orderNotification', data);
            this.socket.on('disconnect', message => {
                console.log('debug disconnect', message);
            });
        });
    }

    getOrderNotification() {
        return Observable.create(observer => {
            var data = 'orderNotification';
            this.socket.removeListener(data);
            this.socket.on(data, data => {
                observer.next(data);
            });
        });
    }
  

    setGeofenceId(id) {
        localStorage.setItem('geofenceId', id);
    }

    singIn(body: LoginBody) {
        return this.http.post(`${this.baseUrl}api/admin/login`, body);
    }
    resetPassword(body){
        return this.http.post(`${this.baseUrl}/api/admin/changePassword`, body);
    }
    getTicket(page){
        return this.http.get(`${this.baseUrl}/api/admin/tickets?page=${page}&limit=10`)
    }
    getAllDriverListt(val,st,ed,page){
        return this.http.get(`${this.baseUrl}/api/admin/getAllDriverList?startTime=${st}&endTime=${ed}&isOnline=${val}&page=${page}`)

    }
    getEarning(id){
        return this.http.get(`${this.baseUrl}/api/admin/vendorEarning?vendorId=${id}`)

    }
    getAllOrderListt(val,st,ed,page){
        return this.http.get(`${this.baseUrl}/api/admin/getAllOrderList?startTime=${st}&endTime=${ed}&type=${val}&page=${page}`)

    }

    getVehicle(){
        return this.http.get(`${this.baseUrl}/api/admin/vehicle`)
  
    }
    getAllUsers(st,ed,page){
        return this.http.get(`${this.baseUrl}/api/admin/UserList?startTime=${st}&endTime=${ed}&page=${page}`)

    }
    generateVendoe(){
        return this.http.get(`${this.baseUrl}/api/admin/generateVendorCSV`)

    }
    generateOrder(){
        return this.http.get(`${this.baseUrl}/api/admin/generateOrderCSV`)

    }
    getAllVendorListt(val,st,ed,page){
        return this.http.get(`${this.baseUrl}/api/admin/getAllVendorList?startTime=${st}&endTime=${ed}&isVerify=${val}&page=${page}`)

    }
    updateTicket(body){
        return this.http.put(`${this.baseUrl}/api/admin/tickets`, body);
    }
    getDetails(id){
        return this.http.get(`${this.baseUrl}/api/admin/tickets/${id}`)
    }
    getOffer(id){
        return this.http.get(`${this.baseUrl}/api/admin/getoffersByid?id=${id}`)
    }
    getFence(){
        return this.http.get(`${this.baseUrl}/api/admin/getFence`)
    }
    getFencee(){
        return this.http.get(`${this.baseUrl}/api/admin/getFence?status=1`)
    }
    addOffer(body) {
        return this.http.post(`${this.baseUrl}/api/admin/addFenceoffers`, body)
    }
    getOrderByUser(body){
        return this.http.post(`${this.baseUrl}/api/admin/getOrdersByUser`, body)
    }
    updateOffer(body) {
        return this.http.post(`${this.baseUrl}/api/admin/updateOffers`, body)
    }
    getOffers(page){
        return this.http.get(`${this.baseUrl}/api/admin/getAllOffers?page=${page}`)
    }
    deleteOffer(body) {
        return this.http.post(`${this.baseUrl}/api/admin/deleteOffer`, body)
    }
  


    //Dashboard api
    // getdashboardData() {
    //     return this.http.get(`${this.baseUrl}/api/admin/dashboard`)
    // }

    getRevenueData(body) {
        return this.http.post(`${this.baseUrl}/api/admin/revenue`, body)
    }
    getDashboard(body) {
        return this.http.post(`${this.baseUrl}/api/admin/orderRevenue`, body)
    }
    getDash(body) {
        return this.http.post(`${this.baseUrl}/api/admin/orderAnalytics`, body)
    }

    getAreaSales(body) {
        return this.http.post(`${this.baseUrl}/api/admin/area-wise-sales`, body)
    }
    searchSubAdmin(body) {
        return this.http.post(`${this.baseUrl}/api/admin/searchSubAdmin`, body)
    }

    /**Delete Image */
    deleteImage(id) {
        return this.http.delete(`${this.baseUrl}/api/admin/delete-image?id=${id}`)
    }
    //sub admin api
    getSubAdmin(page) {
        return this.http.get(`${this.baseUrl}/api/admin/subAdmin?page=${page}`)
    }
    getOrders(id) {
        return this.http.get(`${this.baseUrl}/api/admin/getOrdersByAdmin/${id}`)
    }
    getReport(page){
        return this.http.get(`${this.baseUrl}/api/admin/customer_reported_issue?page=${page}&limit=10`)

    }
    generateCoupon() {
        return this.http.get(`${this.baseUrl}/api/admin/get-new-coupen`)
    }
    getSubAdminDetail(id) {
        return this.http.get(`${this.baseUrl}/api/admin/subAdmin/${id}`)
    }
    addSubAdmin(body) {
        return this.http.post(`${this.baseUrl}/api/admin/subAdmin`, body)
    }
    getOrder(body) {
        return this.http.post(`${this.baseUrl}/api/admin/getReturnedOrders`, body)
    }
    updateSubAdmin(body) {
        return this.http.put(`${this.baseUrl}/api/admin/subAdmin`, body)
    }
    assignDriverByAdmin(body) {
        return this.http.post(`${this.baseUrl}/api/admin/assignDriverByAdmin`, body)
    }

    deleteSubAdmin(id) {
        return this.http.delete(`${this.baseUrl}/api/admin/subAdmin/${id}`)
    }

    subAdminPermission(body) {
        return this.http.post(`${this.baseUrl}/api/admin/permission`, body)
    }

    //vendors api
    getVendor(page, fence, search) {
        return this.http.get(`${this.baseUrl}/api/admin/vendor?page=${page}&fence=${fence}&search=${search}`)
    }
    getVendorDetail(id) {
        return this.http.get(`${this.baseUrl}/api/admin/vendor/${id}`)
    }
    addVendor(body) {
        return this.http.post(`${this.baseUrl}/api/admin/vendor`, body)
    }
    updateVendor(body) {
        return this.http.put(`${this.baseUrl}/api/admin/vendor`, body)
    }
    getVendorProduct(page, id) {
        return this.http.get(`${this.baseUrl}/api/admin/vendor-product?page=${page}&vendorId=${id}`)
    }

    deleteVendor(id) {
        return this.http.delete(`${this.baseUrl}/api/admin/vendor/${id}`)
    }



    //users api
    allUsers(){
        return this.http.get(`${this.baseUrl}/api/admin/allUsers`);

    }
    getallUsers(page) {
        return this.http.post(`${this.baseUrl}/api/admin/user`, page)
    }

    getUserDetail(id) {
        return this.http.get(`${this.baseUrl}/api/admin/user?id=${id}`)
    }

    updateUser(body) {
        return this.http.post(`${this.baseUrl}/api/admin/update-user`, body)
    }

    generateUserCsv() {
        return this.http.get(`${this.baseUrl}/api/admin/generateUserCSV`)
    }

    getUserOrders(id) {
        return this.http.get(`${this.baseUrl}/api/admin/user-orders?userId=${id}`)
    }

    deleteUser(id) {
        return this.http.delete(`${this.baseUrl}/api/admin/delete-user?id=${id}`)
    }

    //category api
    getallCategories(page) {
        return this.http.get(`${this.baseUrl}/api/admin/category?page=${page}`)
    }

    getListAllCategories() {
        return this.http.get(`${this.baseUrl}/api/admin/all-category`);
    }
    getListAllCategoriesById(id) {
        return this.http.get(`${this.baseUrl}/api/admin/all-category?vendorId=${id}`);
    }
    getAllProductsss() {
        return this.http.get(`${this.baseUrl}/api/admin/vendorProducts`);
    }

    addCategory(body) {
        return this.http.post(`${this.baseUrl}/api/admin/category`, body)
    }
    onAccept(body) {
        return this.http.post(`${this.baseUrl}/api/admin/process-return-order`, body);
    }

    updateCategory(body) {
        return this.http.put(`${this.baseUrl}/api/admin/category`, body)
    }

    deleteCategory(id) {
        return this.http.delete(`${this.baseUrl}/api/admin/category?id=${id}`)
    }

    //sub-category api
    getallSubCategories(categoryid, page) {
        return this.http.get(`${this.baseUrl}/api/admin/sub-category?category=${categoryid}&page=${page}`)
    }
    getallSubCategoriess(categoryid) {
        return this.http.get(`${this.baseUrl}/api/admin/getAllSubCategory?category=${categoryid}`)
    }

    addSubCategory(body) {
        return this.http.post(`${this.baseUrl}/api/admin/sub-category`, body);
    }

    editSubCategory(body) {
        return this.http.put(`${this.baseUrl}/api/admin/sub-category`, body);
    }

    deleteSubCategory(id) {
        return this.http.delete(`${this.baseUrl}/api/admin/sub-category?id=${id}`)
    }

    //banner api
    getallBanners(page) {
        return this.http.get(`${this.baseUrl}/api/admin/banner?page=${page}`)
    }

    getAllDeals(){
        return this.http.get(`${this.baseUrl}/api/admin/getDealsAccToACL`)
    }

    addBanner(body) {
        return this.http.post(`${this.baseUrl}/api/admin/banner`, body);
    }

    editBanner(body) {
        return this.http.put(`${this.baseUrl}/api/admin/banner`, body);
    }

    deleteBanner(id) {
        return this.http.delete(`${this.baseUrl}/api/admin/banner?id=${id}`)
    }
    deleteDeals(id) {
        return this.http.post(`${this.baseUrl}/api/admin/updateDeal`,id)
    }

    /**Offer api */
    getAllOffers(page) {
        return this.http.get(`${this.baseUrl}/api/admin/offers?page=${page}`)
    }


    /**orders api */
    getAllOrders(page) {
        return this.http.post(`${this.baseUrl}/api/admin/orders`, page)
    }

    getTransactionOrders(body) {
        return this.http.post(`${this.baseUrl}/api/admin/transaction-orders`, body)
    }

    processOrder(body) {
        return this.http.post(`${this.baseUrl}/api/admin/process-order`, body)
    }

    assignDriver(body) {
        return this.http.post(`${this.baseUrl}/api/admin/assign_driver`, body)
    }

    assignDriverToAll(body) {
        return this.http.post(`${this.baseUrl}/api/admin/assign-driver-to-all`, body)
    }

    acceptTransaction(body) {
        return this.http.post(`${this.baseUrl}/api/admin/accept-transaction`, body)
    }

    generateInvoice(id) {
        return this.http.get(`${this.baseUrl}/api/admin/generate-invoice?id=${id}`)
    }

    //product api
    getallProducts(page, search, type, id, cat) {
        return this.http.get(`${this.baseUrl}/api/admin/product?page=${page}&search=${search}&type=${type}&userId=${id}&category=${cat}`);
    }
    getProducts(type,id) {
        return this.http.get(`${this.baseUrl}/api/admin/product?type=${type}&userId=${id}`);
    }

    getParticularProduct(id) {
        return this.http.get(`${this.baseUrl}/api/admin/product-detail?id=${id}`)
    }

    addProduct(body) {
        return this.http.post(`${this.baseUrl}/api/admin/product`, body);
    }

    updateProduct(body) {
        return this.http.put(`${this.baseUrl}/api/admin/product`, body)
    }

    deleteProduct(id) {
        return this.http.delete(`${this.baseUrl}/api/admin/product?id=${id}`)
    }
    deleteProductt(id) {
        return this.http.put(`${this.baseUrl}/api/admin/comboOffers`,id)
    }
    onApprove(id) {
        return this.http.put(`${this.baseUrl}/api/admin/approvedComboOffers`,id)
    }

    changeProductStatus(body) {
        return this.http.post(`${this.baseUrl}/api/admin/change-status`, body);
    }

    getProductBySubCat(id) {
        return this.http.post(`${this.baseUrl}/api/admin/product-by-reference`, id);
    }

    uploadProductBulk(data) {
        return this.http.post(`${this.baseUrl}/api/admin/bulk-upload`, data);
    }

    generateProductCsv() {
        return this.http.get(`${this.baseUrl}/api/admin/generateProductCSV`)
    }

    approveProduct(data) {
        return this.http.post(`${this.baseUrl}/api/admin/approve-product`, data)
    }

    /**pack api */
    addPack(body: PackBody) {
        return this.http.post(`${this.baseUrl}/api/admin/add-product-pack`, body);
    }
    addDeal(body){
        return this.http.post(`${this.baseUrl}/api/admin/deals`, body);
    }

    getallPacks(id) {
        return this.http.get(`${this.baseUrl}/api/admin/pack?id=${id}`)
    }

    getPackDetails(id) {
        return this.http.get(`${this.baseUrl}/api/admin/pack-detail?id=${id}`)
    }

    updatePackDetails(body) {
        return this.http.put(`${this.baseUrl}/api/admin/edit-product-pack`, body)
    }
    deletePack(id, status) {
        return this.http.delete(`${this.baseUrl}/api/admin/pack?id=${id}&isProductDelete=${status}`)
    }

    uploadPackBulk(file) {
        return this.http.post(`${this.baseUrl}/api/admin/bulk-upload-pack`, file);
    }

    //Inventory pack api
    getPackInventory(page, search, type, id) {
        return this.http.get(`${this.baseUrl}/api/admin/inventory?page=${page}&search=${search}&type=${type}&userId=${id}`)
    }

    /**Driver Api */
    getDrivers(page, search) {
        return this.http.get(`${this.baseUrl}/api/admin/drivers?page=${page}&search=${search}`)
    }
    assignVehicle(body){
        return this.http.post(`${this.baseUrl}/api/admin/assignVehicleToDriver`, body)
    }

    getAllDrivers(fence) {
        return this.http.get(`${this.baseUrl}/api/admin/get-all-driver?fence=${fence}`);
    }
    getDriver(type , gfenc ,id) {
        return this.http.get(`${this.baseUrl}/api/admin/onlineDrivers?driverType=${type}&geofence=${gfenc}&orderId=${id}`);
    }
    getDriverr(type , gfenc,driver,order) {
        return this.http.get(`${this.baseUrl}/api/admin/onlineDrivers?driverType=${type}&geofence=${gfenc}&accpetedDriverId=${driver}&orderId=${order}`);
    }

    addDriver(body) {
        return this.http.post(`${this.baseUrl}/api/admin/drivers`, body)
    }
    editDriver(body) {
        return this.http.put(`${this.baseUrl}/api/admin/drivers`, body)
    }

    getParticularDriver(id) {
        return this.http.get(`${this.baseUrl}/api/admin/driver-detail?id=${id}`)
    }

    generateDriverCsv() {
        return this.http.get(`${this.baseUrl}/api/admin/generateDriverCSV`)
    }

    deleteDriver(id) {
        return this.http.delete(`${this.baseUrl}/api/admin/drivers?id=${id}`)
    }

    /**Geofence api */
    getGeofencingList(page) {
        return this.http.get(`${this.baseUrl}/api/admin/geo-fence?page=${page}`)
    }
    getParticularSubAdmin(id) {
        return this.http.get(`${this.baseUrl}/api/admin/subAdmin/${id}`)
    }

    getAllGeofencingList() {
        return this.http.get(`${this.baseUrl}/api/admin/all-geo-fence`)
    }

    getGeofencing(id) {
        return this.http.get(`${this.baseUrl}/api/admin/geo-fence-detail?id=${id}`)
    }

    creategeoFencing(body) {
        return this.http.post(`${this.baseUrl}/api/admin/add-geo-fence`, body)
    }

    updategeoFencing(data) {
        return this.http.put(`${this.baseUrl}/api/admin/geo-fence`, data)
    }

    deletegeofence(id) {
        return this.http.delete(`${this.baseUrl}/api/admin/geo-fence?id=${id}`)
    }

    //Time slot api
    getTimeSlot(page) {
        return this.http.get(`${this.baseUrl}/api/admin/time-slot?page=${page}`)
    }

    getAllTimeSlot() {
        return this.http.get(`${this.baseUrl}/api/admin/all-time-slot`)
    }

    addTimeSlot(body) {
        return this.http.post(`${this.baseUrl}/api/admin/add-time-slot`, body)
    }

    updateTimeSlot(body) {
        return this.http.put(`${this.baseUrl}/api/admin/edit-time-slot`, body)
    }

    deleteTimeSlot(id) {
        return this.http.delete(`${this.baseUrl}/api/admin/time-slot?id=${id}`)
    }

    //coupon api
    getCoupons(page) {
        return this.http.get(`${this.baseUrl}/api/admin/coupon?page=${page}`)
    }
    getParticularCoupon(id) {
        return this.http.get(`${this.baseUrl}/api/admin/coupon-detail?id=${id}`)
    }

    addCoupon(body) {
        return this.http.post(`${this.baseUrl}/api/admin/coupon`, body)
    }

    updateCoupon(body) {
        return this.http.put(`${this.baseUrl}/api/admin/coupon`, body)
    }
    editProfile(body){
        return this.http.put(`${this.baseUrl}/api/admin/update_profile`, body)
    }

    deleteCoupon(id) {
        return this.http.delete(`${this.baseUrl}/api/admin/coupon?id=${id}`)
    }

    //settingApi
    getSettingData() {
        return this.http.get(`${this.baseUrl}/api/admin/setting`)
    }
    getProfile() {
        return this.http.get(`${this.baseUrl}/api/admin/get_profile`);
    }

    updateSettingData(data) {
        return this.http.post(`${this.baseUrl}/api/admin/setting`, data)
    }

    //Truck Allocation
    getTrucks(page) {
        return this.http.get(`${this.baseUrl}/api/admin/truckAllocation?page=${page}`)
    }

    addTruck(data) {
        return this.http.post(`${this.baseUrl}/api/admin/addTruckAllocation`, data)
    }
    addVehicle(data){
        return this.http.post(`${this.baseUrl}/api/admin/vehicle`, data)
    }

    updateVehicle(data){
        return this.http.put(`${this.baseUrl}/api/admin/vehicle`, data)
    }
    updateTruck(data) {
        return this.http.put(`${this.baseUrl}/api/admin/updateTruckAllocation`, data)
    }

    deleteTruck(id) {
        return this.http.delete(`${this.baseUrl}/api/admin/vehicle/${id}`)
    }

    // Broadcast 
    broadcast(body) {
        return this.http.post(`${this.baseUrl}/api/admin/push-broadcast`, body)
    }

    getCountries() {
        return this.http.get(`${this.baseUrl}/api/admin/getCountries`)
    }

    getCountryById(id) {
        return this.http.get(`${this.baseUrl}/api/admin/getCountryById/${id}`)
    }

    updateCountry(body) {
        return this.http.put(`${this.baseUrl}/api/admin/updateCountry`,body)
    }

    getOrdersByAdmin(body) {
        return this.http.post(`${this.baseUrl}/api/admin/getOrdersByAdmin`,body)
    }

    getOrderByIdAdmin(id){
        return this.http.get(`${this.baseUrl}/api/admin/getOrdersByAdmin/${id}`)
    }

    getOrderbyVendor(limit,page,status){
        return this.http.get(`${this.baseUrl}/api/vendor/get_order_list?limit=${limit}&page=${page}&status=${status}`)
    }

    getOrderByIdVendor(id){
        return this.http.get(`${this.baseUrl}/api/vendor/order/${id}`)
    }

    vendorRejectOrder(data) {
        return this.http.post(`${this.baseUrl}/api/admin/rejected-order`, data)
    }
    assignDriverByAdminToPending(body){
        return this.http.post(`${this.baseUrl}/api/admin/assignDriverByAdminToPendingOrder`, body)   
    }


    getDashboardData() {
        return this.http.get(`${this.baseUrl}/api/admin/get_dashboard_data`)
    }

    sampleProductCSV(){
        return this.http.get(`${this.baseUrl}/api/admin/sampleProductCSV`)
    }

    categoryList(){
        return this.http.get(`${this.baseUrl}/api/admin/category-list`)
    }
}
