import { Component, OnInit } from '@angular/core';
import { resetPassword } from '../b2c/requests/resetPassword';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from '../commonservice/api.service';
import { ToastrService } from 'ngx-toastr';



@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  body = new resetPassword();
  formData = new FormData();
  flags = {
    isAdded: false
  };
  link : any;

  constructor(
    private route : ActivatedRoute,
    private apiSer : ApiService,
    private toastr : ToastrService
  ) { 
    this.link = this.route.snapshot.params.id;
    console.log(this.link)
  }

  ngOnInit(): void {
    this.apiSer.checkForgotPassword(this.link).subscribe((res:any)=>{
      console.log(res);
    })

  }
  reset(){
    // console.log(this.body.newPassword)
    // console.log(this.body.confirmNewPassword)
    if(this.body.newPassword == this.body.confirmNewPassword){
      this.body['id'] = this.link;
      this.apiSer.resetPassword(this.body).subscribe((res:any)=>{
       if(res['status']==200){
         this.toastr.success(res['message']);
       }else{
         this.toastr.error(res['message']);
       }
      })
    }else{
      this.toastr.error('Password must Match');
    }
  }

}
