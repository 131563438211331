import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LoginBody } from '../b2b/requests/login-body';
import { Router } from '@angular/router';
import { vendorSignupBody } from '../b2b/requests/sign-up-body';
import { BehaviorSubject, Observable } from 'rxjs';


declare var swal: any;

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  // imageUrl = "https://appgrowthcompany.com:5018";
  imageUrl = "https://demo.appdukaan.com:5059"
  // imageUrl = "https://groxery.com:5018";
  // imageUrl = 'http://192.168.1.168:4003';
  //imageUrl = 'http://13.232.208.65:4003'
  //  baseUrl = 'http://192.168.1.168:4003';
  // baseUrl = 'https://appgrowthcompany.com:5018';
  // baseUrl = 'https://groxery.com:5018';
  // baseUrl = 'http://13.232.208.65:4003';
  baseUrl = "https://demo.appdukaan.com:5059"



  constructor(private http: HttpClient, private router: Router) { 
   
  }

  signInb2b(body: LoginBody) {
    return this.http.post(`${this.baseUrl}/api/b2b/admin/auth/signIn`, body);
  }
  resetPassword(body) {
    return this.http.post(`${this.baseUrl}/api/admin/reset-password`, body);
  }
  checkForgotPassword(id){
    return this.http.get(`${this.baseUrl}/api/admin/check-forgot-password/${id}`)
}
  signInb2c(body: LoginBody) {
    return this.http.post(`${this.baseUrl}/api/admin/login`, body);
  }

  signUpVendor(body) {
    return this.http.post(`${this.baseUrl}/api/vendor/register`, body);
  }

  sendToken(token: string) {
    localStorage.setItem("Dayfresh_Admin", JSON.stringify(token));
  }

  forgotPassword(body){
    return this.http.post(`${this.baseUrl}/api/admin/forgot-password`,body);
  }

  getToken() {
    return localStorage.getItem("Dayfresh_Admin")
  }

  isLoggedIn() {
    return this.getToken() !== null;
  }

  getTokenB2b() {
    return localStorage.getItem("token");
  }

  getSubAdminDetail(id){
    return this.http.get(`${this.baseUrl}/api/admin/subAdmin/${id}`)
}

  isLoggedInB2b() {
    return this.getTokenB2b() !== null;
  }


  // logout() {
  //   localStorage.removeItem("Prem_Admin");
  // localStorage.removeItem("LoggedInUser");
  // this.router.navigate(["Login"]);

  // swal.fire({
  //   title: 'Are you sure?',
  //   text: "You want to Log out",
  //   type: 'warning',
  //   showCancelButton: true,
  //   confirmButtonColor: '#3085D6',
  //   cancelButtonColor: '#d33',
  //   confirmButtonText: 'Yes',
  //   allowOutsideClick: false,
  // }).then((result) => {
  //   if (result.value) {
  //     localStorage.removeItem("Prem_Admin");
  //     this.router.navigate(["login"]);
  //   }
  //   else if (result.dismiss === swal.DismissReason.cancel) {
  //     // Swal.fire(
  //     //   'Cancelled',
  //     //   'Your imaginary file is safe :)',
  //     //   'error'
  //     // )

  //   }
  // })


  // }
  vendorToken(token){
    return this.http.get(`${this.baseUrl}/api/admin/verifyVendorEmail/${token}`)
}


}
