import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/b2c/services/api.service';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { ModalComponent } from './modal/modal.component'


@Component({
  selector: 'app-ticket',
  templateUrl: './ticket.component.html',
  styleUrls: ['./ticket.component.scss']
})
export class TicketComponent implements OnInit {
  dataa : any[];
  totalItems: number;
  currentPage = 0;
  serialNumber = 0;
  count: any;


  constructor(private apiSer : ApiService,
    public dialog: MatDialog) { }

  ngOnInit(): void {
    this.getTickets();
  }
  getTickets(){
    this.apiSer.getTicket(this.currentPage).subscribe((res:any)=>{
      this.dataa = res['data'];
      this.count = res['count'];
      this.totalItems = res.count;
    })

  }
  onPageChange(pages) {
    this.currentPage = pages.page - 1;
    this.serialNumber = 10 * this.currentPage - 10
    this.getTickets();
  }
  openDialog(id): void {
    const dialogRef = this.dialog.open(ModalComponent, {
      width: '700px',
      data : id
      // data: {name: "yfhj", animal: "sdz"}
    });

    dialogRef.afterClosed().subscribe(result => {
      
    //   console.log('The dialog was closed', result);
      if(result === 'yes'){
        this.currentPage = 0;
        this.getTickets();
      }
    //   // this.animal = result;
    });
  }

}
