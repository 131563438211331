import { Component, OnInit } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { ApiService } from '../commonservice/api.service';

@Component({
  selector: 'app-email-verification',
  templateUrl: './email-verification.component.html',
  styleUrls: ['./email-verification.component.scss']
})
export class EmailVerificationComponent implements OnInit {
  token: any;
  message: any;
  constructor(private api: ApiService,private activatedRoute: ActivatedRoute) { 

}

  ngOnInit(): void {
   this.activatedRoute.params.subscribe((params) =>{ 
      this.token= params['token'];
      this.onSubmit();
     })
  }


  onSubmit() {
    this.api.vendorToken(this.token).subscribe(response=>{
      this.message= response['message']
    })
  }

}
