import { Injectable } from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {Router} from '@angular/router';
import { ToastrService } from "ngx-toastr";

@Injectable({
  providedIn: 'root'
})
export class GetInterceptorService {

  constructor(private router: Router,
    private toastr: ToastrService) {}
 
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(tap((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {
      }
    }, (error: any) => {
        if (error['status'] === 401) {
          // this.toastr.error('Your session is expired, please log in.');
          localStorage.clear();
          return this.router.navigateByUrl('/');
        }else{
        }
    }));
  }

}
