import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
// import { LocalStorageService } from "angular-web-storage";

@Injectable({
  providedIn: 'root'
})
export class SetInterceptorService {

  constructor(
    private router: Router
  ) {
  }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // console.log('work');
    const clonedReq = this.handleRequest(req);
    return next.handle(clonedReq);
  }

  handleRequest(req: HttpRequest<any>) {
    const user = localStorage.getItem('Dayfresh_Admin');
    // console.log(user);
    const admin = JSON.parse(user)
    // console.log(admin); 
    let authReq;
    authReq = req.clone({
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': admin ? admin.authToken : ''
      })
    });
    authReq = req.clone({
      headers: new HttpHeaders({
        'Authorization': admin ? admin.authToken : '',
        'country':'india'
      })
    });
    return authReq;
  }
}
