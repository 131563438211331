import { Component, OnInit, Inject } from '@angular/core';
import { validateVerticalPosition } from '@angular/cdk/overlay';
import { FormBuilder , FormControl,FormGroup , Validators } from '@angular/forms';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '../../../../services/api.service';
import { NewOrderListComponent } from '../new-order-list.component';

@Component({
  selector: 'app-modall',
  templateUrl: './modall.component.html',
  styleUrls: ['./modall.component.scss']
})
export class ModallComponent implements OnInit {

  form : FormGroup;
  submitted : boolean = false;
  drivers: any=[];

  constructor(
    public dialogRef: MatDialogRef<ModallComponent>,
    @Inject(MAT_DIALOG_DATA) public data: NewOrderListComponent,
    private fb : FormBuilder,
    private apiSer : ApiService,
    private toastr : ToastrService
  ) { }

  ngOnInit(): void { 
    this.getDriver();
  } 

  getDriver(){
    const type = 'normal';
    this.apiSer.getDriverr(type,this.data['gen'],this.data['driver'],this.data['id']).subscribe((response : any)=>{
      this.drivers = response['data'];
      console.log(this.drivers);
    })
  }

  
    onNoClick(): void {
      this.dialogRef.close();
      // onClick(){
      //   this.submitted = true;
      //   if(this.form.valid){
      //     const body ={
      //       // id: this.data,
      //       status: "Closed",
      //       adminReply: this.form.value.adminReply
      //     }
      //     this.apiSer.updateTicket(body).subscribe((res:any)=>{
      //       if(res['success']){
      //         this.dialogRef.close('yes');
      //         this.toastr.success('Resolved Successfully');
      //       }else{
      //         this.toastr.error(res['message']);
      
      //       }
      //     })
      //   }
        // console.log(this.adminReply)
        
      // }
    }
    change(id){
      console.log(id);
      // console.log(id);
      // console.log(booking);
      // if(orderVendor.length > 0){
        if(id){
            const body ={
              bookingId : this.data['id'],
              driverId : id
            }
            this.apiSer.assignDriverByAdminToPending(body).subscribe((res : any)=>{
              if(res['success']){
                this.toastr.success(res['message']);
                this.dialogRef.close();
                // if(this.currentPage>0){
                //   this.currentPage = this.currentPage - 1;
                // }
                // this.currentPage = this.currentPage - 1;
                // this.getAllOrders();
                // this.currentPage = this.currentPage + 1;
              }else{
                this.toastr.error(res['message']);
              }
            })
        }else{
          this.toastr.error('Select Driver')
        }
      // }else{
      //   this.toastr.error('Something went wrong');
      // }
   
  
    }

}



