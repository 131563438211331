import { Component, OnInit } from '@angular/core';
import { FormBuilder , FormControl,FormGroup , Validators } from '@angular/forms';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-mat-dialog',
  templateUrl: './mat-dialog.component.html',
  styleUrls: ['./mat-dialog.component.scss']
})
export class MatDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<MatDialogComponent>) { }

  ngOnInit(): void {
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
  onClick(){
    // this.submitted = true;
    // if(this.form.valid){
    //   const body ={
    //     id: this.data,
    //     status: "Closed",
    //     adminReply: this.form.value.adminReply
    //   }
    //   this.apiSer.updateTicket(body).subscribe((res:any)=>{
    //     if(res['success']){
          this.dialogRef.close('yes');
    //       this.toastr.success('Resolved Sucessfully');
    //     }else{
    //       this.toastr.error(res['message']);

    //     }
    //   })
    // }
    // console.log(this.adminReply)
    
  }

}
