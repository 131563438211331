import { Component, OnInit } from '@angular/core';

import { FilterBody } from '../../../requests/filter-body';
import { Orders } from 'src/app/models/Orders';
import { Driver } from 'src/app/models/driver';
import { Resp } from 'src/app/models/Resp';
import { ApiService } from 'src/app/b2c/services/api.service';
import base from 'src/app/b2c/services/api.service';
import { ToastrService } from 'ngx-toastr';
import { saveAs } from 'file-saver';
import { ThemePalette } from '@angular/material/core';
import * as moment from 'moment';
import { Router, ActivatedRoute, Params } from '@angular/router';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { ModallComponent} from '../new-order-list/modall/modall.component'


@Component({
  selector: 'app-new-order-list',
  templateUrl: './new-order-list.component.html',
  styleUrls: ['./new-order-list.component.scss']
})
export class NewOrderListComponent implements OnInit {
  url = base;
  totalItems: number;
  currentPage = 0;
  serialNumber = 0;
  status = '';
  filterBody = new FilterBody();
  selectedAddress: any;
  selectedProduct: Array<any> = [];
  requestId: string;
  newOrderList = [];
  selectedDriver: any;
  driverList: Array<Driver> = [];
  loading: boolean = true;
  selectOrder: any = ''
  color: ThemePalette = 'primary';
  todayDate: any = moment().format('MMMM Do YYYY');
  tommorrowDate: any;
  tokenVal;
  allCreatedDate: any = [];
  role: any;
  access: any;
  adminId: any;
  totalAmount: any = [];
  timeSlotList: any;
  geofenceList: any;
  fence = '';
  timeSloat = '';
  userId;
  driver: any;

  constructor(
    private api: ApiService, private toaster: ToastrService,
    private router: Router,
    private toastrService: ToastrService,
    private activatedRoute: ActivatedRoute,
    public dialog: MatDialog
  ) {
  }

  ngOnInit() {
    this.role = JSON.parse(localStorage.getItem('Dayfresh_Admin'));
    this.access = JSON.parse(localStorage.getItem('permission'));
    this.adminId = this.role._id;
    var d = new Date()
    let tommorrow = d.setDate(d.getDate() + 1);
    this.tommorrowDate = moment(tommorrow).format('MMMM Do YYYY');
    this.activatedRoute.params.subscribe((params: Params) => {
      if(params.id){
        this.userId = params.id;
        this.getAllOrders();
      }else{
        this.getAllOrders();
      }
    });
    this.getAllGeofence();
    this.getAllTimeSlot(); 

  }

  getAllOrders() {
    this.allCreatedDate = [];
    let data = {
      status: this.status,
      page: this.currentPage,
      search: this.selectOrder,
      userType: this.role.type,
      adminId: this.adminId,
      timeSlot: this.timeSloat,
      geofence: this.fence,
      limit: 10
    }
    if(this.userId){
      data['userId'] = this.userId;
    }
    this.api.getOrdersByAdmin(data).subscribe((response: any) => {
      if (!response.success) return;
      this.newOrderList = response.data;
      this.loading = false;
      this.totalItems = response.count;
      // this.currentPage = this.currentPage + 1;
      for (let i = 0; i < this.newOrderList.length; i++) {
        let d = moment(this.newOrderList[i].createdAt).format('MMMM Do YYYY');
        this.allCreatedDate.push(d);
      }
    });
  }
  setClass(list: Array<any>) {
    return list.findIndex(o => o.status == 'New');
  }

  getFilterOrder() {
    this.currentPage = 0;
    this.loading = true;
    this.getAllOrders();
  }

  geneateInvoice(id,stat) {
    if(stat == 'Rejected') return this.errorToast('Order Rejected.')
    this.loading = true;
    this.api.generateInvoice(id).subscribe((response: any) => {
      this.loading = false;
      if (!response.success) this.errorToast(response.message)
      saveAs(`${this.url}/${response.data}`, "invoice.pdf")
      this.successToast("Invoice generated Successfully!");
    })
  }

  getNewOrder() {
    this.api.newOrder().subscribe((response: any) => {
      if (!response.success) return
      this.toastrService.success(response['message']).onTap.subscribe(
        (func) => {
          this.router.navigate(['/b2c/order']);
        }
      );
      this.playAudio();
    });
  }

  playAudio() {
    const audio = new Audio();
    audio.src = 'assets/sounds/notification-sound1.mp3';
    audio.load();
    audio.play();
  }

  getAllVendorByOrders(limit, page, status) {
    this.api.getOrderbyVendor(limit, page, status).subscribe((response: any) => {
      if (!response.success) return;
      this.newOrderList = response.data;
      this.loading = false;
      this.totalItems = response.count;
      for (let i = 0; i < this.newOrderList.length; i++) {
        let d = moment(this.newOrderList[i].createdAt).format('MMMM Do YYYY');
        this.allCreatedDate.push(d);
      }

    });

  }


  getAllGeofence() {
    this.api.getAllGeofencingList().subscribe((response: any) => {
      this.geofenceList = response.data
    })
  }

  getAllTimeSlot() {
    this.api.getAllTimeSlot().subscribe((response: any) => {
      this.timeSlotList = response.data
    })
  }

  processOrder(transactionId, status) {
    let data = {
      transactionId,
      status
    }
    this.api.acceptTransaction(data).subscribe((response: any) => {
      if (!response.success) return this.errorToast(response.message);
      this.successToast1('Order accepted successfully');
      this.getAllOrders();
    })
  }



  addOneDay(date) {
    // console.log(date)
    var nextDay = moment(date).add(1, 'days').format('MMMM Do YYYY');
    // console.log(nextDay);
    return nextDay;
  }
  // getDrivers() {
  //   this.api.getAllDriverWithoutPagination().subscribe((response: Resp) => {
  //     if (!response.success) return;
  //     this.driverList = response.data;
  //   });
  // }
  onSelectProduct(product: any) {
    // console.log(product);
    this.selectedProduct = product;

  }

  searchOrder() {
    this.selectOrder = this.selectOrder.trim();
    this.currentPage = 0;
    this.getAllOrders();
  }

  resetOrder() {
    this.selectOrder = '';
    this.currentPage = 0;
    this.getAllOrders();
  }

  setGeofenceId(geofence) {
    this.api.setGeofenceId(geofence);
  }
  // processOrder(id: string, state: string, index: number) {
  //   // if (this.orderList[index].status == 'Packing' && !this.orderList[index].driver) return this.error('Please assign driver first.');
  //   this.api.processOrder(id, {status: state}).subscribe((response: Resp) => {
  //     if (!response.success) return;
  //     this.successToast(`Order ${state} successfully!`);
  //     this.orderList[index].status = response.data.status;
  //   });
  // }

  change(data,booking){
    console.log(data);
    // console.log(id);
    console.log(booking);
    // if(orderVendor.length > 0){
      if(data){
          const body ={
            bookingId : booking,
            driverId : data
          }
          this.api.assignDriverByAdminToPending(body).subscribe((res : any)=>{
            if(res['success']){
              this.successToast1(res['message']);
              // if(this.currentPage>0){
              //   this.currentPage = this.currentPage - 1;
              // }
              this.currentPage = this.currentPage - 1;
              this.getAllOrders();
              this.currentPage = this.currentPage + 1;
            }else{
              this.errorToast(res['message']['message']);
            }
          })
      }else{
        this.errorToast('Select Driver')
      }
    // }else{
    //   this.toastr.error('Something went wrong');
    // }
 

  }
  openDialog(id,gen,driver): void {
    console.log(id);
    
    const dialogRef = this.dialog.open(ModallComponent, {
      width: '700px',
      data : {id,gen,driver}
      // data: {name: "yfhj", animal: "sdz"}
    });

    dialogRef.afterClosed().subscribe(result => {
      
    //   console.log('The dialog was closed', result);
      // if(result === 'yes'){
      //   this.currentPage = 0;
      //   this.getTransactionOrders();
      // }
    //   // this.animal = result;
    });
  }
  onChange(type : any,gfen,id){
    this.api.getDriver(type , gfen,id).subscribe((response : any)=>{
      this.driver = response['data'];
      console.log(this.driver)
    })
  }

  pageChange(page) {
    this.currentPage = page.page - 1;
    this.serialNumber = 10 * (this.currentPage + 1) - 10;
    this.getAllOrders();
  }


  successToast(message) {
    this.toaster.info(message)
  }

  successToast1(message) {
    this.toaster.success(message)
  }

  errorToast(message) {
    this.toaster.error(message);
  }

  refreshOrder(){
    this.loading = true;
    this.currentPage = 0;
    this.getAllOrders();
  }
}
