import { Component, Inject, OnInit } from '@angular/core';
import { validateVerticalPosition } from '@angular/cdk/overlay';
import { FormBuilder , FormControl,FormGroup , Validators } from '@angular/forms';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '../../../services/api.service';
import { VendorsComponent } from '../vendors.component';

@Component({
  selector: 'app-modaal',
  templateUrl: './modaal.component.html',
  styleUrls: ['./modaal.component.scss']
})
export class ModaalComponent implements OnInit {
  earn : any;
  currency : any;

  constructor(
    public dialogRef: MatDialogRef<ModaalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: VendorsComponent,
    private fb : FormBuilder,
    private apiSer : ApiService,
    private toastr : ToastrService
  ) { }

  ngOnInit(): void {
    this.getEarnings();
  }
  getEarnings(){
    const body = this.data;
    this.apiSer.getEarning(body).subscribe((res : any)=>{
      if(res.success){
        this.earn = res.data;
        this.currency = res.currency.currencySymbol;
      }else{
        this.toastr.error(res.message);
        this.earn = 'No records Found'
      }

    })
  }
  onNoClick(): void {
    this.dialogRef.close();
    // onClick(){
    //   this.submitted = true;
    //   if(this.form.valid){
    //     const body ={
    //       // id: this.data,
    //       status: "Closed",
    //       adminReply: this.form.value.adminReply
    //     }
    //     this.apiSer.updateTicket(body).subscribe((res:any)=>{
    //       if(res['success']){
    //         this.dialogRef.close('yes');
    //         this.toastr.success('Resolved Successfully');
    //       }else{
    //         this.toastr.error(res['message']);
    
    //       }
    //     })
    //   }
      // console.log(this.adminReply)
      
    // }
  }

}
