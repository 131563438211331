import { validateVerticalPosition } from '@angular/cdk/overlay';
import { Component, OnInit , Inject } from '@angular/core';
import { FormBuilder , FormControl,FormGroup , Validators } from '@angular/forms';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '../../../services/api.service';
import { TicketComponent } from '../ticket.component';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {

  form : FormGroup;
  submitted : boolean = false;

  constructor(
    public dialogRef: MatDialogRef<ModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: TicketComponent,
    private fb : FormBuilder,
    private apiSer : ApiService,
    private toastr : ToastrService
  ) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      // email: ["", [Validators.required, Validators.pattern(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/)]],
      adminReply: ["", [Validators.required]],
      // address: ["", [Validators.required, this.noWhitespaceValidator]],
      // phone: ["", [Validators.required, this.noWhitespaceValidator]],
      // photo: [""],
    });
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
  onClick(){
    this.submitted = true;
    if(this.form.valid){
      const body ={
        id: this.data,
        status: "Closed",
        adminReply: this.form.value.adminReply
      }
      this.apiSer.updateTicket(body).subscribe((res:any)=>{
        if(res['success']){
          this.dialogRef.close('yes');
          this.toastr.success('Resolved Successfully');
        }else{
          this.toastr.error(res['message']);

        }
      })
    }
    // console.log(this.adminReply)
    
  }

}
