import { Component, OnInit, Inject } from '@angular/core';
import { validateVerticalPosition } from '@angular/cdk/overlay';
import { FormBuilder , FormControl,FormGroup , Validators } from '@angular/forms';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { DriverComponent } from '../driver.component';
import { ApiService } from '../../../services/api.service';

@Component({
  selector: 'app-modallll',
  templateUrl: './modallll.component.html',
  styleUrls: ['./modallll.component.scss']
})
export class ModallllComponent implements OnInit {


  form : FormGroup;
  submitted : boolean = false;
  drivers: any=[];

  constructor(
    public dialogRef: MatDialogRef<ModallllComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DriverComponent,
    private fb : FormBuilder,
    private apiSer : ApiService,
    private toastr : ToastrService
  ) { }

  ngOnInit(): void {
    this.getVehicle();
  }
  getVehicle(){
    this.apiSer.getVehicle().subscribe((res : any)=>{
      this.drivers = res.data; 
      console.log(this.drivers);
    })
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  change(id){
    console.log(id);
    // console.log(id);
    // console.log(booking);
    // if(orderVendor.length > 0){
  
      if(id){
          const body ={
            vehicleId : id ,
            driverId : this.data
          }
          this.apiSer.assignVehicle(body).subscribe((res : any)=>{
            if(res['success']){
              this.toastr.success(res['message']);
              this.dialogRef.close();
              // if(this.currentPage>0){
              //   this.currentPage = this.currentPage - 1;
              // }
              // this.currentPage = this.currentPage - 1;
              // this.getAllOrders();
              // this.currentPage = this.currentPage + 1;
            }else{
              this.toastr.error(res['message']);
            }
          })
      }else{
        this.toastr.error('Select Vehicle')
      }
    // }else{
    //   this.toastr.error('Something went wrong');
    // }
 

  }

}
